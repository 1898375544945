import React, { createContext, useCallback, useRef, useState } from "react";
import { PromiseSwitcher } from "../utils/PromiseSwicher";
import PyodideWorker, { StdoutHandler, StdoutMessage } from "./PyodideWorker";
import { PreMainPostCode, ReadFileResult, RunPythonResult } from "./types";

interface PyodideContextValue {
  loading: boolean;
  error: Error | undefined;
  result: RunPythonResult;
  stdoutMessages: StdoutMessage[];
  runPython: (code: PreMainPostCode) => void;
  readFile: (path: string) => Promise<ReadFileResult>;
  installPackage: (name: string) => Promise<void>;
}

const PyodideContext = createContext<PyodideContextValue>({
  loading: false,
  error: undefined,
  result: undefined,
  stdoutMessages: [],
  runPython: (async () => {}) as any,
  readFile: (async () => {}) as any,
  installPackage: (async () => {}) as any,
});

// There is only a single PyodideWorker, all providers use it.
// It attempts to not persist any global variables after execution.
const pyodideWorker = new PyodideWorker();

async function interruptAndRunPython(
  code: PreMainPostCode,
  stdoutHandler?: StdoutHandler
): Promise<RunPythonResult> {
  await pyodideWorker.interrupt();
  return await pyodideWorker.runPython(code, stdoutHandler);
}

export const PyodideProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [result, setResult] = useState<RunPythonResult>(undefined);
  const [stdoutMessages, setStdoutMessages] = useState<StdoutMessage[]>([]);
  const stdoutHandler = useRef<StdoutHandler>(() => undefined);

  const promiseSwitcher = useRef(
    new PromiseSwitcher<RunPythonResult>({
      onResolve: setResult,
      onReject: setError,
      finally: () => setLoading(false),
    })
  );

  const runPython = useCallback((code: PreMainPostCode) => {
    setLoading(true);
    setError(undefined);
    setStdoutMessages([]);
    const newStdoutHandler: StdoutHandler = (msg) => {
      if (stdoutHandler.current === newStdoutHandler) {
        setStdoutMessages((msgs) => [...msgs, msg]);
      }
    };
    promiseSwitcher.current.set(interruptAndRunPython(code, newStdoutHandler));
    stdoutHandler.current = newStdoutHandler;
  }, []);

  const readFile = useCallback(
    async (path: string): Promise<ReadFileResult> =>
      pyodideWorker.readFile(path),
    []
  );

  const installPackage = useCallback(
    async (name: string): Promise<void> => pyodideWorker.installPackage(name),
    []
  );

  return (
    <PyodideContext.Provider
      value={{
        loading,
        error,
        result,
        stdoutMessages,
        runPython,
        readFile,
        installPackage,
      }}
    >
      {children}
    </PyodideContext.Provider>
  );
};

export const usePyodide = () => {
  return React.useContext(PyodideContext);
};
