import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PanelGroup, Panel } from "react-resizable-panels";
import { debounce } from "lodash-es";

import { usePyodide } from "../pyodide/react";
import Preview from "./Preview";
import Editor from "./Editor";
import Examples, { Example, generateExampleForPackage } from "./Examples";
import examples from "../examples";
import { PreMainPostCode } from "../pyodide/types";
import Header from "./Header";
import parseTrace from "../utils/parseTrace";
import {
  PanelResizeHandleHorizontal,
  PanelResizeHandleVertical,
} from "./PanelResizeHandle";
import Console from "./Console";
import { useQueryParams } from "../utils/queryParams";

const Playground = () => {
  const { queryParams, updateQueryParams } = useQueryParams();
  const { result, stdoutMessages, error, runPython, installPackage } =
    usePyodide();

  const [selectedExample, setSelectedExample] = useState<Example>(() => {
    const exampleFromParams = examples.find(
      (example) => example.name === queryParams.example
    );
    return exampleFromParams || examples[0];
  });

  const [code, setCode] = useState(queryParams.code ?? selectedExample.code);

  const updateExampleAndCode = useCallback(
    ({ code, example }: { code: string; example: Example }) => {
      console.log("Updating example and code", example.internalId, code);
      setCode(code);
      setSelectedExample(example);
      updateQueryParams(
        {
          example: example.name,
          code:
            examples.find((e) => e.name === example.name)?.code === code
              ? undefined
              : code,
        },
        { replace: true }
      );
    },
    [updateQueryParams]
  );

  useEffect(() => {
    if (
      queryParams.example &&
      !examples.find((example) => example.name === queryParams.example)
    ) {
      // If there's an example in the query params but it's not found in examples,
      // assume it's a package name and generate an example for it
      if (queryParams.code) {
        installPackage(queryParams.example);
      } else {
        generateExampleForPackage(
          installPackage,
          updateExampleAndCode,
          queryParams.example
        );
      }
    }
  }, [
    queryParams.example,
    queryParams.code,
    installPackage,
    updateExampleAndCode,
  ]);

  const onEditorChange = useCallback(
    (code: string) => {
      updateExampleAndCode({ code, example: selectedExample });
    },
    [selectedExample, updateExampleAndCode]
  );

  const onSelectExample = useCallback(
    (example: Example) => {
      updateExampleAndCode({ code: example.code, example });
    },
    [updateExampleAndCode]
  );

  const runPythonDebounced = useMemo(
    () =>
      debounce((code: string) => {
        runPython(getCode(code));
      }, 200),
    [runPython]
  );

  useEffect(() => {
    runPythonDebounced(code);
  }, [code, runPythonDebounced]);

  const parsedTrace = useMemo(() => {
    if (result === undefined) return undefined;
    return parseTrace(result);
  }, [result]);

  return (
    <div className="h-full flex flex-col">
      <Header hideCta={queryParams.hideCta} />
      <div className="flex-1 grid grid-rows-[min-content_1fr]">
        <Examples
          examples={examples}
          selectedExample={selectedExample}
          onSelect={onSelectExample}
          updateExampleAndCode={updateExampleAndCode}
        />
        <PanelGroup direction="horizontal">
          <Panel id="code" defaultSize={50}>
            <PanelGroup direction="vertical">
              <Panel id="editor" className="relative h-full">
                {!queryParams.hideCta ? (
                  <div className="absolute left-0 bottom-3 right-0 z-10 px-3">
                    <div className="flex justify-center items-center text-center text-balance">
                      <h3 className="text-base font-normal mr-4 text-gray-500 bg-white/90 shadow-[0_0_5px_10px_rgba(255,255,255,.9)]">
                        Try Kolo on your own Python codebase.
                      </h3>
                      <a
                        href="https://docs.kolo.app/en/latest/tutorial.html"
                        target="_blank"
                        className="px-2 py-1 text-base font-medium text-white bg-gray-900 mr-2"
                      >
                        Install Kolo
                      </a>
                    </div>
                  </div>
                ) : undefined}
                <Editor
                  // we set a key in order to reset the editor when we switch between examples
                  key={selectedExample.internalId}
                  defaultCode={code}
                  onChange={onEditorChange}
                />
              </Panel>
              <PanelResizeHandleHorizontal />
              <Panel id="console" defaultSize={20} className="h-full">
                <Console messages={stdoutMessages} error={error} />
              </Panel>
            </PanelGroup>
          </Panel>
          <PanelResizeHandleVertical />
          <Panel id="preview" className="flex">
            <Preview {...parsedTrace} />
          </Panel>
        </PanelGroup>
      </div>
    </div>
  );
};

const PRE_EXECUTION_CODE = `
import kolo
from kolo.db import load_trace_from_db, setup_db, list_traces_from_db
print("Kolo version", kolo.version.__version__)
`;

const POST_EXECUTION_CODE = `
db_path = setup_db()
most_recent_trace_id, _, _ = list_traces_from_db(db_path, count=1)[0]
msgpack_data, json_data = load_trace_from_db(db_path, most_recent_trace_id)
msgpack_data
`;

const getCode = (mainCode: string): PreMainPostCode => {
  return {
    pre: PRE_EXECUTION_CODE,
    main: mainCode,
    post: POST_EXECUTION_CODE,
  };
};

export default Playground;

// Do we even need to have history support in the demo page?
// Probably not, could just keep it all in memory and not save it at all??
// -> Currently we're only saving kolo to the virtual in memory emscripten fs which gets wiped after page reload:
// https://emscripten.org/docs/api_reference/Filesystem-API.html#memfs
