import {
  DjangoTemplateData,
  ExecutionTreeNode,
  FrameSpanData,
  NestedBackgroundJobData,
  NestedServedRequestData,
  NestedTestData,
  OutboundRequestData,
  RecordedFrameTypeName,
  RecordedLogMessage,
  SQLQueryData,
} from "./model";
import type { InvocationID } from "./model";
import { getLineNumberAndPath } from "./utils";

export abstract class ProcessedNode {
  public readonly traceId: InvocationID;
  public readonly index: number;
  public readonly children: ProcessedNode[];
  public readonly parent: ProcessedNode | null;
  public readonly all_children_count: number;
  // I think we possibly want a parent count here as well?
  public readonly type: RecordedFrameTypeName;
  public readonly name: string;
  public readonly frame_id: string;
  public readonly start: number;
  public readonly end: number;
  // Making this writable for now, while we're still waiting to add timestamps for all node types (specifically served requests)
  public durationMs: number;
  public readonly ancestorCount: number;

  abstract data: { [key: string]: any };
  // Question: should nodes directly be able to access siblings?
  // currently one root node can't access sibling root nodes.
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID,
    start: number,
    end: number
  ) {
    this.traceId = traceId;
    this.index = basicExecutionTreeNode.index;
    this.parent = parent;
    this.ancestorCount = parent ? parent.ancestorCount + 1 : 0;
    this.children = basicExecutionTreeNode.children.map((child) =>
      makeProcessedNode(child, this, traceId)
    );
    this.all_children_count = basicExecutionTreeNode.all_children_count;
    this.type = basicExecutionTreeNode.type;
    this.name = basicExecutionTreeNode.name;
    this.frame_id = basicExecutionTreeNode.frame_id;
    this.start = start;
    this.end = end;
    this.durationMs =
      new Date(end * 1000).getTime() - new Date(start * 1000).getTime();
  }

  public *dfs(): Generator<ProcessedNode> {
    // Search the subtree from the current node
    yield this;
    for (const child of this.children) {
      yield* child.dfs();
    }
  }

  public *ancestors(): Generator<ProcessedNode> {
    let ancestor: ProcessedNode = this;
    while (ancestor.parent) {
      yield ancestor.parent;
      ancestor = ancestor.parent;
    }
  }

  public findAncestorByFrameId(frame_id: string): ProcessedNode | null {
    for (const ancestor of this.ancestors()) {
      if (ancestor.frame_id === frame_id) {
        return ancestor;
      }
    }
    return null;
  }

  public isPartOfBackgroundJob(): boolean {
    // Note: Does not check whether this node is itself a BackgroundJobNode.
    for (const ancestor of this.ancestors()) {
      if (ancestor instanceof BackgroundJobNode) {
        return true;
      }
    }
    return false;
  }

  public isPartOfTemplateRendering(): boolean {
    // Note: Does not check whether this node is itself a DjangoTemplateNode.
    for (const ancestor of this.ancestors()) {
      if (ancestor instanceof DjangoTemplateNode) {
        return true;
      }
    }
    return false;
  }

  abstract compactTreeLine(): string;

  public fullCompactTreeLine(): string {
    // todo: possibly indentation should be replaced by like an indentation specific icon
    // instead of relying on spaces.
    const indentation = "  ".repeat(this.ancestorCount);
    return `${indentation}${this.index} ${this.compactTreeLine()} ${this.durationMs}ms ${this.all_children_count !== 0 ? `${this.all_children_count}c` : ""}`;
  }
}

export function makeProcessedNode(
  executionTreeNode: ExecutionTreeNode,
  parent: ProcessedNode | null,
  traceId: InvocationID
): ProcessedNode {
  if (executionTreeNode.type === "frame_span") {
    return new FrameSpanNode(executionTreeNode, parent, traceId);
  } else if (executionTreeNode.type === "sql_query") {
    return new SQLQueryNode(executionTreeNode, parent, traceId);
  } else if (executionTreeNode.type === "nested_background_job") {
    return new BackgroundJobNode(executionTreeNode, parent, traceId);
  } else if (executionTreeNode.type === "nested_served_http_request") {
    return new ServedRequestNode(executionTreeNode, parent, traceId);
  } else if (executionTreeNode.type === "outbound_http_request") {
    return new OutboundRequestNode(executionTreeNode, parent, traceId);
  } else if (executionTreeNode.type === "nested_test") {
    return new TestNode(executionTreeNode, parent, traceId);
  } else if (executionTreeNode.type === "django_template") {
    return new DjangoTemplateNode(executionTreeNode, parent, traceId);
  } else if (executionTreeNode.type === "log_message") {
    return new LogMessageNode(executionTreeNode, parent, traceId);
  } else {
    throw new Error(
      "makeProcessedNode: No specific node type found for " +
        executionTreeNode.type
    );
  }
}

export class FrameSpanNode extends ProcessedNode {
  public readonly data: FrameSpanData;
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID
  ) {
    const data = basicExecutionTreeNode.data as FrameSpanData;
    const start = data.call_frame.timestamp;
    const end = data.return_frame.timestamp;
    super(basicExecutionTreeNode, parent, traceId, start, end);
    this.data = data;
  }

  compactTreeLine(): string {
    const { line: callLineNumber } = getLineNumberAndPath(this.data.call_frame);
    const { line: returnLineNumber } = getLineNumberAndPath(
      this.data.return_frame
    );
    return `${this.name} (${callLineNumber}-${returnLineNumber})`;
  }
}

export class SQLQueryNode extends ProcessedNode {
  public readonly data: SQLQueryData;
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID
  ) {
    const data = basicExecutionTreeNode.data as SQLQueryData;
    super(
      basicExecutionTreeNode,
      parent,
      traceId,
      data.call_timestamp,
      data.return_timestamp
    );
    this.data = data;
  }

  compactTreeLine(): string {
    const firstQueryWord = this.data.query.split(" ")[0];

    return `${firstQueryWord}`;
  }
}

export class BackgroundJobNode extends ProcessedNode {
  public readonly data: NestedBackgroundJobData;
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID
  ) {
    const data = basicExecutionTreeNode.data as NestedBackgroundJobData;
    super(
      basicExecutionTreeNode,
      parent,
      traceId,
      data.call_timestamp,
      data.return_timestamp
    );
    this.data = data;
  }

  compactTreeLine(): string {
    return `${this.data.subtype} ${this.data.name}`;
  }
}

export class ServedRequestNode extends ProcessedNode {
  public readonly data: NestedServedRequestData;
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID
  ) {
    // We don't yet record timestamps for served requests, hence this workaround :(
    super(basicExecutionTreeNode, parent, traceId, NaN, NaN);
    this.data = basicExecutionTreeNode.data as NestedServedRequestData;
    this.durationMs = this.data.response.ms_duration;
  }

  compactTreeLine(): string {
    return `⬇ ${this.data.request.method} ${this.data.request.path_info}`;
  }
}

export class OutboundRequestNode extends ProcessedNode {
  public readonly data: OutboundRequestData;
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID
  ) {
    const data = basicExecutionTreeNode.data as OutboundRequestData;
    const start = data.request.timestamp;
    const end = data.response.timestamp;
    super(basicExecutionTreeNode, parent, traceId, start, end);
    this.data = data;
  }

  compactTreeLine(): string {
    return `⬆ ${this.data.request.method} ${this.data.request.url}`;
  }
}

export class TestNode extends ProcessedNode {
  public readonly data: NestedTestData;
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID
  ) {
    const data = basicExecutionTreeNode.data as NestedTestData;
    super(
      basicExecutionTreeNode,
      parent,
      traceId,
      data.call_timestamp,
      data.return_timestamp
    );
    this.data = data;
  }

  compactTreeLine(): string {
    if (this.data.test_class) {
      return `${this.data.test_class}.${this.data.test_name}`;
    }
    return `${this.data.test_name}`;
  }
}

export class DjangoTemplateNode extends ProcessedNode {
  public readonly data: DjangoTemplateData;
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID
  ) {
    const data = basicExecutionTreeNode.data as DjangoTemplateData;
    super(
      basicExecutionTreeNode,
      parent,
      traceId,
      data.call_timestamp,
      data.return_timestamp
    );
    this.data = data;
  }

  compactTreeLine(): string {
    return `Template: ${this.data.template}`;
  }
}

export class LogMessageNode extends ProcessedNode {
  // Always a leaf node with no children
  public readonly data: RecordedLogMessage;
  constructor(
    basicExecutionTreeNode: ExecutionTreeNode,
    parent: ProcessedNode | null,
    traceId: InvocationID
  ) {
    // Log messages are always leaf nodes
    super(basicExecutionTreeNode, parent, traceId, NaN, NaN);
    this.data = basicExecutionTreeNode.data as RecordedLogMessage;
  }

  compactTreeLine(): string {
    return `Log: ${this.data.msg.toString().substring(0, 20)}`;
  }
}
