import { max, min, sum } from "d3-array";
import { hierarchy, tree } from "d3-hierarchy";

import type {
  DjangoTemplate,
  ExcludesFalsy,
  ExecutionTreeNode,
  FrameSpanData,
  NestedBackgroundJob,
  NestedOutboundRequest,
  NestedSQLQuery,
} from "../../../model";

import type {
  VizTreeNode,
  FrameHierarchyPlot,
  FrameHierarchy,
  VizRootNode,
} from "./types";

function convertExecutionTreeNodeToVizTreeNode(
  executionTreeNode: ExecutionTreeNode
): Exclude<VizTreeNode, VizRootNode>[] | undefined {
  const node = {
    index: executionTreeNode.index,
    all_children_count: executionTreeNode.all_children_count,
    name: executionTreeNode.name,
    // fill with dummy values
    index_call: NaN,
    index_return: NaN,
    frame_id: executionTreeNode.frame_id,
  };
  const children = executionTreeNode.children
    .map(convertExecutionTreeNodeToVizTreeNode)
    .flat()
    .filter(Boolean as any as ExcludesFalsy);

  if (executionTreeNode.type === "frame_span") {
    const { call_frame, return_frame } =
      executionTreeNode.data as FrameSpanData;
    const start: number = call_frame.timestamp;
    const end: number = return_frame.timestamp;
    const total = end - start;
    return [
      {
        ...node,
        // TODO: Can we bring this more in line with other types in the future
        // in that we don't reference "...Data"
        data: executionTreeNode.data as FrameSpanData,
        type: "frame_span",
        timing: {
          start,
          end,
          total,
        },
        children,
      },
    ];
  } else if (executionTreeNode.type === "nested_background_job") {
    const backgroundJob = executionTreeNode as NestedBackgroundJob;

    const call_timestamp = backgroundJob.data.call_timestamp ?? NaN;
    const return_timestamp = backgroundJob.data.return_timestamp ?? NaN;
    const total = return_timestamp - call_timestamp;

    return [
      {
        ...node,
        type: "nested_background_job",
        data: backgroundJob.data,
        children,
        timing: {
          start: call_timestamp,
          end: return_timestamp,
          total,
        },
      },
    ];
  } else if (executionTreeNode.type === "sql_query") {
    const sqlQuery = executionTreeNode as NestedSQLQuery;

    const { call_timestamp, return_timestamp } = sqlQuery.data;
    const total = return_timestamp - call_timestamp;
    return [
      {
        ...node,
        ...sqlQuery,
        timing: {
          start: call_timestamp,
          end: return_timestamp,
          total,
        },
        // It turns out that in rare cases, a sql_query _can_ have children.
        // For example when there is a log message ABOUT the sql query emitted
        // by Django.
        // To make sure that "...sqlQuery" above doesn't accidentally include
        // children, we explicitly set children to an empty array here.
        //
        // In the future where we're able to show log messages in the viz, we
        // can set "children: children" here and just display the log message(s).
        children: [],
      },
    ];
  } else if (executionTreeNode.type === "outbound_http_request") {
    const nestedOutboundRequest = executionTreeNode as NestedOutboundRequest;

    const { request, response } = nestedOutboundRequest.data;

    const requestTimestamp = request.timestamp;
    if (typeof requestTimestamp !== "number") {
      throw new Error(
        "Expected requestTimestamp to be number but got" + requestTimestamp
      );
    }
    let responseTimestamp = response?.timestamp ?? NaN;
    if (typeof responseTimestamp !== "number") {
      throw new Error(
        "Expected responseTimestamp to be number but got" + responseTimestamp
      );
    }
    const total = responseTimestamp - requestTimestamp;

    return [
      {
        ...node,
        type: "outbound_http_request",
        data: nestedOutboundRequest.data,
        all_children_count: 0,
        children: [],
        timing: {
          start: requestTimestamp,
          end: responseTimestamp,
          total,
        },
      },
    ];
  } else if (executionTreeNode.type === "django_template") {
    const template = executionTreeNode as DjangoTemplate;

    const call_timestamp = template.data.call_timestamp;
    const return_timestamp = template.data.return_timestamp;
    const total = return_timestamp - call_timestamp;

    return [
      {
        ...node,
        children,
        type: "django_template",
        data: template.data,
        timing: {
          start: call_timestamp,
          end: return_timestamp,
          total,
        },
      },
    ];
  } else {
    if (executionTreeNode.children.length === 0) {
      return undefined;
    } else {
      return executionTreeNode.children
        .map(convertExecutionTreeNodeToVizTreeNode)
        .flat()
        .filter(Boolean as any as ExcludesFalsy);
    }
  }
}

export function constructRootNode({
  executionTreeNodes,
}: {
  executionTreeNodes: Readonly<ExecutionTreeNode>[];
}): VizRootNode {
  const children: Exclude<VizTreeNode, VizRootNode>[] = executionTreeNodes
    .map(convertExecutionTreeNodeToVizTreeNode)
    .flat()
    .filter(Boolean as any as ExcludesFalsy);

  const start = min(children, (f) => f.timing.start) ?? 0;
  const end = max(children, (f) => f.timing.end) ?? 0;
  // Construct a root node in order to have a valid tree structure.
  // This root node is (visually) omitted, but used in generating the tree layout.
  const rootNode: VizRootNode = {
    type: "root",
    all_children_count: sum(
      executionTreeNodes.map(
        (executionTreeNode) => executionTreeNode.all_children_count
      )
    ),
    children,
    // fill with dummy values
    index: NaN,
    index_call: NaN,
    index_return: NaN,
    timing: {
      start,
      end,
      total: end - start,
    },
    frame_id: "frm_artificial_root_node",
  };
  const [rootNodeWithIndexes] = injectIndexes(rootNode);
  return rootNodeWithIndexes;
}

function injectIndexes<NodeType extends VizTreeNode>(
  node: NodeType,
  initialIndex: number = 0
): [NodeType, number] {
  const result: NodeType = { ...node };
  let index = initialIndex;
  result.index_call = index++;
  if (!["sqlQuery", "outbound_http_request"].includes(node.type)) {
    result.children = node.children?.map((n: VizTreeNode) => {
      const [newNode, newIndex] = injectIndexes(n, index);
      index = newIndex;
      return newNode;
    });
  }
  result.index_return = index++;
  return [result, index];
}

export function hierarchyFromFrame(frame: VizTreeNode): FrameHierarchy {
  return hierarchy(frame, (frame) => frame.children);
}

export function treeFromFrame(
  frame: VizTreeNode,
  size: [number, number]
): FrameHierarchyPlot {
  const node = hierarchyFromFrame(frame);
  const createTree = tree<VizTreeNode>()
    .size(size)
    .separation((a, b) => (a.parent === b.parent ? 1 : 1) / a.depth);
  return createTree(node);
}

export type FrameIndex = VizTreeNode["index"];

export function getFrameIndex(frame: VizTreeNode): number {
  return frame.index;
}
