import React from "react";

import { PanelResizeHandle as PanelResizeHandleDefault } from "react-resizable-panels";

export const PanelResizeHandleVertical = () => (
  <PanelResizeHandleDefault className="border-l border-gray-300 data-[resize-handle-state=hover]:border-gray-800 data-[resize-handle-state=drag]:border-gray-800 transition-colors" />
);

export const PanelResizeHandleHorizontal = () => (
  <PanelResizeHandleDefault className="border-t border-gray-300 data-[resize-handle-state=hover]:border-gray-800 data-[resize-handle-state=drag]:border-gray-800 transition-colors" />
);
