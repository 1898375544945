import type { AnyPythonValue } from "../frames-of-interest-model";

declare global {
  interface Array<T> {
    toPython(this: Array<T>, pretty: boolean, depth: number): string;
  }

  interface String {
    toPython(this: String, pretty: boolean, depth: number): string;
  }

  interface Number {
    toPython(this: Number, pretty: boolean, depth: number): string;
  }

  interface Boolean {
    toPython(this: Boolean, pretty: boolean, depth: number): string;
  }

  interface Uint8Array {
    toPython(this: Uint8Array, pretty: boolean, depth: number): string;
  }

  interface Map<K, V> {
    toPython(this: Map<K, V>, pretty: boolean, depth: number): string;
  }

  interface BigInt {
    toPython(this: BigInt, pretty: boolean, depth: number): string;
  }
}

export interface HasToPython {
  toPython(pretty: boolean, depth: number): string;
}

function format_elements(
  elements: Array<string>,
  pretty: boolean,
  depth: number
): string {
  if (pretty && elements.length > 1) {
    const indent = "    ".repeat(depth);
    const ending_indent = "    ".repeat(depth - 1);
    const joined = elements.join(",\n" + indent);
    return `\n${indent}${joined}\n${ending_indent}`;
  } else {
    return elements.join(", ");
  }
}

Array.prototype.toPython = function <T extends { toPython: Function }>(
  this: Array<T>,
  pretty: boolean,
  depth: number
): string {
  const elements = this.map((x) => toPython(x, pretty, depth + 1));
  const joined = format_elements(elements, pretty, depth);
  return `[${joined}]`;
};

String.prototype.toPython = function (
  this: String,
  _pretty: boolean,
  _depth: number
): string {
  return JSON.stringify(this);
};

Number.prototype.toPython = function (
  this: Number,
  _pretty: boolean,
  _depth: number
): string {
  return `${this}`;
};

BigInt.prototype.toPython = function (
  this: BigInt,
  _pretty: boolean,
  _depth: number
): string {
  return `${this}`;
};

Boolean.prototype.toPython = function (
  this: Boolean,
  _pretty: boolean,
  _depth: number
): string {
  if (this === true || (this instanceof Boolean && this.valueOf() === true)) {
    return "True";
  }
  return "False";
};

function to_bytes(b: number): string {
  if ((b >= 0x20 && b <= 0x7e) || b === 9 || b === 10 || b === 13) {
    return String.fromCodePoint(b);
  }
  const char = b.toString(16).padStart(2, "0");
  return `\\x${char}`;
}

Uint8Array.prototype.toPython = function (
  this: Uint8Array,
  _pretty: boolean,
  _depth: number
): string {
  const bytes = Array.from(this);
  const chars = bytes.map((b) => to_bytes(b));
  const joined = chars.join("");
  const repr = `b"${joined}"`;
  return repr;
};

Map.prototype.toPython = function <
  K extends { toPython: Function },
  V extends { toPython: Function },
>(this: Map<K, V>, pretty: boolean, depth: number): string {
  const elements = Array.from(
    this,
    ([k, v]: [any, any]) =>
      `${toPython(k, pretty, depth + 1)}: ${toPython(v, pretty, depth + 1)}`
  );
  const joined = format_elements(elements, pretty, depth);
  return `{${joined}}`;
};

export class PythonObject {
  repr: string;

  constructor(repr: string) {
    this.repr = repr;
  }

  toPython(_pretty: boolean, _depth: number): string {
    return `${this.repr}`;
  }
}

function parseTimeZone(timezone: string): string {
  const re = /(\+|\-)(\d{2}):(\d{2})(.+)?/;
  let parts = re.exec(timezone)!;
  let sign = parts[1];
  let hours = parseInt(parts[2]);
  let minutes = parseInt(parts[3]);

  if (hours === 0 && minutes === 0 && !parts[4]) {
    return "datetime.timezone.utc";
  }

  let args = `hours=${hours}, minutes=${minutes}`;
  if (parts[4]) {
    const seconds_re = /:(\d{2})(.+)?/;
    let seconds_parts = seconds_re.exec(parts[4])!;
    let seconds = parseInt(seconds_parts[1]);
    args += `, seconds=${seconds}`;

    if (seconds_parts[2]) {
      const microseconds_re = /\.(\d{6})/;
      let microsecond_parts = microseconds_re.exec(seconds_parts[2])!;
      let microseconds = parseInt(microsecond_parts[1]);
      args += `, microseconds=${microseconds}`;
    }
  }
  if (sign === "-") {
    return `datetime.timezone(-datetime.timedelta(${args}))`;
  }
  return `datetime.timezone(datetime.timedelta(${args}))`;
}

export class PythonDateTime implements HasToPython {
  isoformat: string;

  constructor(isoformat: string) {
    this.isoformat = isoformat;
  }

  toPython(_pretty: boolean, _depth: number): string {
    const re = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{6})(.+)?/;
    let parts = re.exec(this.isoformat)!;
    let year = parseInt(parts[1]);
    let month = parseInt(parts[2]);
    let day = parseInt(parts[3]);
    let hour = parseInt(parts[4]);
    let minute = parseInt(parts[5]);
    let second = parseInt(parts[6]);
    let microsecond = parseInt(parts[7]);
    if (parts[8]) {
      let timezone = parseTimeZone(parts[8]);
      return `datetime.datetime(${year}, ${month}, ${day}, ${hour}, ${minute}, ${second}, ${microsecond}, tzinfo=${timezone})`;
    }
    return `datetime.datetime(${year}, ${month}, ${day}, ${hour}, ${minute}, ${second}, ${microsecond})`;
  }
}

export class PythonDate implements HasToPython {
  isoformat: string;

  constructor(isoformat: string) {
    this.isoformat = isoformat;
  }

  toPython(_pretty: boolean, _depth: number): string {
    const re = /(\d{4})-(\d{2})-(\d{2})/;
    let parts = re.exec(this.isoformat)!;
    let year = parseInt(parts[1]);
    let month = parseInt(parts[2]);
    let day = parseInt(parts[3]);
    return `datetime.date(${year}, ${month}, ${day})`;
  }
}

export class PythonTime implements HasToPython {
  isoformat: string;

  constructor(isoformat: string) {
    this.isoformat = isoformat;
  }

  toPython(_pretty: boolean, _depth: number): string {
    const re = /(\d{2}):(\d{2}):(\d{2}).(\d{6})(.+)?/;
    let parts = re.exec(this.isoformat)!;
    let hour = parseInt(parts[1]);
    let minute = parseInt(parts[2]);
    let second = parseInt(parts[3]);
    let microsecond = parseInt(parts[4]);
    if (parts[5]) {
      let timezone = parseTimeZone(parts[5]);
      return `datetime.time(${hour}, ${minute}, ${second}, ${microsecond}, tzinfo=${timezone})`;
    }
    return `datetime.time(${hour}, ${minute}, ${second}, ${microsecond})`;
  }
}

export class PythonTuple<T extends AnyPythonValue> implements HasToPython {
  elements: Array<T>;

  constructor(elements: Array<T>) {
    this.elements = elements;
  }

  toPython(pretty: boolean, depth: number): string {
    const elements = this.elements.map((x) => toPython(x, pretty, depth + 1));
    const joined = format_elements(elements, pretty, depth);
    const comma = this.elements.length === 1 ? "," : "";
    return `(${joined}${comma})`;
  }
}

export class PythonSet implements HasToPython {
  elements: Set<any>;

  constructor(elements: Array<any>) {
    this.elements = new Set(elements);
  }

  toPython(pretty: boolean, depth: number): string {
    let elements = Array.from(this.elements);
    elements = elements.map((x) => toPython(x, pretty, depth + 1));
    const joined = format_elements(elements, pretty, depth);
    return `{${joined}}`;
  }
}

export class PythonFrozenSet implements HasToPython {
  elements: Set<any>;

  constructor(elements: Array<any>) {
    this.elements = new Set(elements);
  }

  toPython(pretty: boolean, depth: number): string {
    let elements = Array.from(this.elements);
    elements = elements.map((x) => toPython(x, pretty, depth + 1));
    const joined = format_elements(elements, pretty, depth);
    return `frozenset({${joined}})`;
  }
}

export class BrokenRepr implements HasToPython {
  pyclass: string;

  constructor(pyclass: string) {
    this.pyclass = pyclass;
  }

  toPython(_pretty: boolean, _depth: number): string {
    return `KoloSerializationError: Unserializable \`${this.pyclass}\` instance`;
  }
}

export function toPython(
  value: AnyPythonValue,
  pretty: boolean = false,
  depth: number = 1
): string {
  if (value === null) {
    return "None";
  }
  if (value === undefined) {
    console.log("toPython unexpectedly got undefined");
    return "None";
  }

  // Convert primitives to their object form so that the above prototypes
  // take effect. This doesn't seem necessary in Chrome (prototypes are
  // work even on primitives?), but not sure that's guaranteed.
  if (typeof value === "string") value = Object(value) as String;
  if (typeof value === "number") value = Object(value) as Number;
  if (typeof value === "boolean") value = Object(value) as Boolean;
  if (typeof value === "bigint") value = Object(value) as BigInt;

  // TODO: It would be nice to refactor this to make this catch
  // a bit more specific and easy to follow the control flow.
  try {
    // @ts-ignore
    return value.toPython(pretty, depth);
  } catch {
    const elements = Object.entries(value)
      .filter(([k, v]: [any, any]) => k !== undefined && v !== undefined)
      .map(([k, v]: [any, any]) => {
        return `${toPython(k, pretty, depth + 1)}: ${toPython(
          v,
          pretty,
          depth + 1
        )}`;
      });
    const joined = format_elements(elements, pretty, depth);
    return `{${joined}}`;
  }
}
