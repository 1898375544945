import {
  ErrorFromPyodideWorker,
  MessageFromPyodideWorker,
  MessageToPyodideWorker,
  PreMainPostCode,
  ReadFileResult,
  RunPythonResult,
} from "./types";

export type StdoutMessage = {
  type: "stdout" | "stderr";
  content: string;
};
export type StdoutHandler = (params: StdoutMessage) => void;

async function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default class PyodideWorker {
  private worker: Worker;
  private currentId: number;
  private callbacks: {
    [key: number]: {
      resolve: (value: any) => void;
      reject: (value: ErrorFromPyodideWorker) => void;
      stdout?: StdoutHandler;
    };
  };
  private interruptBuffer: Uint8Array | undefined;
  public waitUntilInitialized: Promise<void>;

  constructor() {
    // NOTE: for webpack to correctly detect the worker script and bundle it separately, we need
    // to keep this line as-is. It needs to be an explicit constructor inside a constructor:
    // `new Worker(new URL(...))`
    // It doesn't work with `super(new URL(..))`
    this.worker = new Worker(new URL("./worker/worker.ts", import.meta.url));

    this.currentId = 0;
    this.callbacks = {};

    let resolveInitialized: (_: void) => void;
    this.waitUntilInitialized = new Promise((resolve) => {
      resolveInitialized = resolve;
    });

    this.worker.onmessage = (event: MessageEvent<MessageFromPyodideWorker>) => {
      const type = event.data.type;
      if (type === "initialized") {
        this.attachInterruptBuffer();
        resolveInitialized();
      } else if (type === "result") {
        const { id, result, error } = event.data;
        if (id !== undefined && id in this.callbacks) {
          if (error) {
            this.callbacks[id].reject(error);
            console.error(error);
          } else {
            this.callbacks[id].resolve(result);
          }
          delete this.callbacks[id];
        }
      } else if (type === "stdout" || type === "stderr") {
        const { id, content } = event.data;
        if (id !== undefined && id in this.callbacks) {
          this.callbacks[id].stdout?.({ type, content });
        }
      } else {
        // exhaustive check
        const _: never = event.data;
      }
    };
  }

  private attachInterruptBuffer() {
    try {
      this.interruptBuffer = new Uint8Array(new SharedArrayBuffer(1));
      this.postMessage({
        cmd: "setInterruptBuffer",
        interruptBuffer: this.interruptBuffer,
      });
    } catch (error) {
      console.log(
        "Could not set up interrupts for Pyodide (need SharedArrayBuffers, see MDN)"
      );
    }
  }

  private generateId(): number {
    return (this.currentId = (this.currentId + 1) % Number.MAX_SAFE_INTEGER);
  }

  // We are assuming the returned value is a msgpack buffer
  runPython(
    code: PreMainPostCode,
    stdout?: StdoutHandler
  ): Promise<RunPythonResult> {
    return new Promise((resolve, reject) => {
      const id = this.generateId();
      this.callbacks[id] = { resolve, reject, stdout };
      this.uninterrupt(); // clear in case it was previously set
      this.postMessage({
        cmd: "execute",
        id,
        code,
      });
    });
  }

  readFile(path: string): Promise<ReadFileResult> {
    return new Promise((resolve, reject) => {
      const id = this.generateId();
      this.callbacks[id] = { resolve, reject };
      this.postMessage({
        cmd: "readFile",
        id,
        path,
      });
    });
  }

  installPackage(name: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const id = this.generateId();
      this.callbacks[id] = { resolve, reject };
      this.postMessage({
        cmd: "installPackage",
        id,
        packageName: name,
      });
    });
  }

  // See: https://pyodide.org/en/stable/usage/keyboard-interrupts.html
  async interrupt(): Promise<void> {
    if (this.interruptBuffer) {
      this.interruptBuffer[0] = 2; // SIGINT
      // We wait a little bit until the interrupt buffer is reset to 0,
      // which means that the interrupt was handled
      for (let i = 0; i < 6; i++) {
        await wait(50);
        // halt if the interrupt buffer is reset
        if (this.interruptBuffer[0] === 0) return;
      }
      // just continue exection if it doesn't clear after a while...
    }
  }

  uninterrupt(): void {
    if (this.interruptBuffer) {
      this.interruptBuffer[0] = 0; // Clear
    }
  }

  postMessage(message: MessageToPyodideWorker): void {
    this.worker.postMessage(message);
  }
}
