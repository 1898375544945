import React from "react";
import Wrapper from "./Wrapper";

function Logo() {
  return (
    <a href="#" className="block">
      <img
        src="/kolo-logo-full.svg"
        width={147}
        height={58}
        style={{ height: "1.75rem", width: "auto" }}
      />
    </a>
  );
}

const Header = ({ hideCta }: { hideCta?: boolean }) => {
  return (
    <header>
      <Wrapper className="py-2 flex items-center">
        <Logo />
        <h1 className="ml-1 text-gray-500">Playground</h1>
        <nav className="flex-1"></nav>
        {!hideCta ? (
          <a
            href="https://docs.kolo.app/en/latest/tutorial.html"
            target="_blank"
            className="px-2 py-1 text-base font-medium text-white bg-gray-900"
          >
            Install Kolo
          </a>
        ) : undefined}
      </Wrapper>
    </header>
  );
};

export default Header;
