import { orderBy } from "lodash-es";

import { makeExecutionTree } from "../../../build-frame-tree";
import { ProcessedTrace } from "../../../trace";
import { loadMsgpackTrace, msgpackLoad } from "../../../extension/msgpack";
import { ExecutionTreeInfo } from "../../../frames-of-interest-model";
import { ExecutionTreeNode, NestedFrameSpan } from "../../../model";
import { constructRootNode } from "../../../view/app/util/frames";

export type FunctionWithFrameSpans = {
  /**
   * A unique id to identify the function.
   * We can't use only the `name` because it's not unique –
   * the getter, setter and deleter can have the same name.
   * See https://github.com/kloppindustries/kolo/issues/1761
   */
  id: string;
  module: string;
  name: string;
  defLineNumber: number;
  frameSpans: NestedFrameSpan[];
};

function* walk(
  nodes: Iterable<ExecutionTreeNode>,
  shouldVisitChildren?: (node: ExecutionTreeNode) => boolean
): Iterable<ExecutionTreeNode> {
  for (const node of nodes) {
    yield node;
    const visitChildren = shouldVisitChildren?.(node) ?? true;
    if (visitChildren) yield* walk(node.children, shouldVisitChildren);
  }
}

function isFrameSpan(node: ExecutionTreeNode): node is NestedFrameSpan {
  return node.type === "frame_span";
}

function groupBy<T, K extends string | number>(
  items: Iterable<T>,
  getKey: (_: T) => K
): Map<K, T[]> {
  const result = new Map<K, T[]>();
  for (const item of items) {
    const key = getKey(item);
    if (!result.has(key)) {
      result.set(key, [item]);
    } else {
      result.get(key)!.push(item);
    }
  }
  return result;
}

function* iterateFrameSpans(
  executionTreeInfo: ExecutionTreeInfo
): Iterable<NestedFrameSpan> {
  for (const node of walk(executionTreeInfo.executionTreeNodes)) {
    if (isFrameSpan(node)) {
      yield node;
    }
  }
}

function constructFunction(
  frameSpans: NestedFrameSpan[]
): FunctionWithFrameSpans {
  const { path } = frameSpans[0]!.data.call_frame;
  const [module, lineNumberStr] = path.split(":");
  const lineNumber = parseInt(lineNumberStr);
  const name = frameSpans[0]!.name;
  const id = getFunctionId(frameSpans[0]);

  return {
    id,
    module,
    name,
    defLineNumber: lineNumber,
    frameSpans,
  };
}

export function getFunctionId(
  frameSpan: Pick<NestedFrameSpan, "data" | "name">
): string {
  const { path } = frameSpan.data.call_frame;
  const [, lineNumberStr] = path.split(":");
  const lineNumber = parseInt(lineNumberStr);
  const name = frameSpan.name;
  const id = `${name} :${lineNumber}`;
  return id;
}

export default function (trace: Uint8Array) {
  // TODO: Could this make better sue of the ProcessedTrace class?
  const rawMsgpackMap = msgpackLoad(trace);
  const invocation = loadMsgpackTrace(trace);
  const framesOfInterest = ProcessedTrace.getMainFramesOfInterest(invocation);
  const executionTreeInfo = makeExecutionTree(framesOfInterest);
  const frameSpansByPath = groupBy(
    iterateFrameSpans(executionTreeInfo),
    (node) => node.data.call_frame.path
  );
  const functions = orderBy(
    Array.from(frameSpansByPath.values()).map(constructFunction),
    [(f) => f.module, (f) => f.defLineNumber]
  );
  const vizTree = constructRootNode({
    executionTreeNodes: executionTreeInfo.executionTreeNodes,
  });
  return { executionTreeInfo, functions, msgpack: rawMsgpackMap, vizTree };
}
