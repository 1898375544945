import React, { useEffect, useRef } from "react";
import clsx from "clsx";

import { StdoutMessage } from "../pyodide/PyodideWorker";
import ScrollablePane from "./ScrollablePane";

const Console = ({
  messages,
  error,
}: {
  messages: StdoutMessage[];
  error?: Error;
}) => {
  const scrollingContainerRef = useRef<HTMLDivElement>(null);
  // Always scroll to bottom when props change.
  // This is not ideal, since the user might scroll up while the program is
  // logging messages, but that's more complicated to implement.
  useEffect(() => {
    const container = scrollingContainerRef.current;
    if (container) {
      const maxScrollTop = container.scrollHeight - container.clientHeight;
      container.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [messages, error]);

  const header = <h4>Console</h4>;
  return (
    <ScrollablePane
      header={header}
      scrollingContainerRef={scrollingContainerRef}
    >
      <div className="font-mono text-xs text-gray-600">
        {messages.map((msg, index) => (
          <StdoutMessageLog key={index} {...msg} />
        ))}
        {error && <FatalErrorLog error={error} />}
      </div>
    </ScrollablePane>
  );
};

const StdoutMessageLog = ({ type, content }: StdoutMessage) => (
  <div
    className={clsx(
      "px-1 py-0.5 border-b border-gray-200 whitespace-pre-wrap",
      {
        "text-red-700": type === "stderr",
      }
    )}
  >
    {/* Do not collapse line for an empty print("") */}
    {content === "" ? " " : content}
  </div>
);

const FatalErrorLog = ({ error }: { error: Error }) => {
  return (
    <div className="px-1 py-0.5">
      <div className="p-2 rounded bg-red-100 text-red-800 whitespace-pre-wrap">
        {error?.message}
      </div>
    </div>
  );
};

export default Console;
