import React, { useCallback } from "react";
import clsx from "clsx";
import Wrapper from "./Wrapper";
import { usePyodide } from "../pyodide/react";
import pypiData from "../pypi.json";

export type Example = {
  name: string;
  internalId: string;
  title: string;
  code: string;
};

export function getRandomPyPIPackage(): string {
  const packages = pypiData.rows.map((row) => row.project);
  return (
    packages[Math.floor(Math.random() * packages.length)] || "No package found"
  );
}

export const generateExampleForPackage = async (
  installPackage: (packageName: string) => Promise<void>,
  updateExampleAndCode: (args: { code: string; example: Example }) => void,
  packageName?: string
) => {
  const selectedPackage = packageName || getRandomPyPIPackage();

  const code = `with kolo.enabled():
    # Loading ${selectedPackage}...
    ...
`;
  updateExampleAndCode({
    code,
    example: {
      name: selectedPackage,
      internalId: `${selectedPackage}-${Date.now()}`,
      title: selectedPackage,
      code,
    },
  });

  console.log("Installing PyPI package:", selectedPackage);
  await installPackage(selectedPackage);

  const generateCode = async (packageName: string) => {
    // Hard-coded (and public) API key for now. Too hard to do rate limiting...
    // Limited to $20/month spend and restricted to gpt-4o-mini to limit abuse.
    const apiToken =
      "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";
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: `
You generate a short Python code example (5-10 lines) that demonstrates a basic usage of the given package.
You must include the correct import statement. Please also provide a (very brief) comment explaining the code.
You must reply only with valid PYTHON code and no prose around it (unless in a comment).
Your response needs to be executable python like would be found in a .py file, this is very important.
Do not include code fences like \`\`\`python!

The code you generate needs to be self contained and not rely on any external files or data. You cannot really make network requests, due
to CORS restrictions in the environment we're running in (pyodide). There is also no processing or threading available, so avoid anything to do with that.

Additionally, the example code that you generate needs to be traced by a python library called kolo. kolo is installed
and can be imported using \`import kolo\`. kolo can be enabled using the \`with kolo.enable()\` context manager or the @kolo.enable decorator.

By default, kolo traces only user code and not library code, so we need to additionally configure it like so:

"pandas" in the example below is a path segment. As kolo encounters frames which are generated in a file that contains "pandas", the frame
will be included. Similarly, "pandas/overwhelming.py" is a file that kolo will ignore and not capture frames from.

Examples:

with kolo.enable(
  config={
      # tell Kolo to capture traces within pandas but ignore a file which overwhelms the user
      "filters": {"include_frames": ["pandas"], "ignore_frames": ["pandas/overwhelming.py"]},
  }
):
  ...

@kolo.enable(
  config={
      # tell Kolo to capture traces within pandas but ignore a file which overwhelms the user
      "filters": {"include_frames": ["pandas"], "ignore_frames": ["pandas/overwhelming.py"]},
  }
)
def main():
  ...

It is YOUR job to write an appropriate kolo config. Often just the package name in include_frames will be enough, but 
sometimes a library is so large that we need to be more specific with our includes or exclude certain files. But you should aim to start out broad
and with a simple config.
`,
          },
          {
            role: "user",
            content: `Package:'${packageName}'`,
          },
        ],
        max_tokens: 300,
      }),
    });

    const data = await response.json();
    return data.choices[0].message.content;
  };

  const generatedCode = await generateCode(selectedPackage);

  updateExampleAndCode({
    code: generatedCode,
    example: {
      name: selectedPackage,
      internalId: `${selectedPackage}-${Date.now()}`,
      title: selectedPackage,
      code: generatedCode,
    },
  });
};

const RandomPackageButton: React.FC<{
  updateExampleAndCode: (args: { code: string; example: Example }) => void;
}> = ({ updateExampleAndCode }) => {
  const { installPackage } = usePyodide();

  const handleClick = () =>
    generateExampleForPackage(installPackage, updateExampleAndCode);

  return (
    <button
      onClick={handleClick}
      className="px-4 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
    >
      Random Python Package
    </button>
  );
};

// const Examples = ({ selectedId }: { selectedId: number, onSelect: (example: Example) => void }) => {
const Examples = ({
  examples,
  selectedExample,
  onSelect,
  updateExampleAndCode,
}: {
  examples: Example[];
  selectedExample: Example;
  onSelect: (example: Example) => void;
  updateExampleAndCode: (args: { code: string; example: Example }) => void;
}) => {
  let examplesPlusPackage = examples.slice();

  if (
    !examplesPlusPackage.find(
      (example) => example.name === selectedExample.name
    )
  ) {
    examplesPlusPackage.push(selectedExample);
  }

  return (
    <Wrapper className="border-b border-gray-300 overflow-x-auto overflow-y-hidden scrollbar-hide">
      <div className="flex">
        {examplesPlusPackage.map((example) => (
          <Example
            key={example.name}
            example={example}
            isSelected={selectedExample.name === example.name}
            onSelect={onSelect}
          />
        ))}
        <RandomPackageButton updateExampleAndCode={updateExampleAndCode} />
      </div>
    </Wrapper>
  );
};

const Example = ({
  example,
  onSelect,
  isSelected,
}: {
  example: Example;
  onSelect: (example: Example) => void;
  isSelected: boolean;
}) => {
  return (
    <button
      key={example.name}
      className={clsx(
        "py-1 mr-5 border-b-[3px] text-left whitespace-nowrap hover:text-gray-900",
        isSelected
          ? "border-blue-500 text-gray-900"
          : "border-transparent text-gray-500"
      )}
      onClick={() => onSelect(example)}
    >
      <h3 className="text-sm font-medium text-inherit">{example.title}</h3>
    </button>
  );
};

export default Examples;
