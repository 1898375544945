import React, { useEffect, useMemo, useRef, useState } from "react";
import { usePyodide } from "../pyodide/react";
import FrameTreeSvelte from "../../../view/app/components/FrameTree.svelte";
import { VizRootNode } from "../../../view/app/util/types";
import { getFunctionId } from "../utils/parseTrace";
import { OnSelectionState, SelectionState } from "../utils/types";
import { getFrameIndex } from "../../../view/app/util/frames";

const Visualization = ({
  vizTree,
  selected,
  onSelect,
  hovered,
  onHover,
}: {
  vizTree: VizRootNode;
  selected: SelectionState;
  onSelect: OnSelectionState;
  hovered: SelectionState;
  onHover: OnSelectionState;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { readFile } = usePyodide();

  // A hack to have `containerRef` as an implicit dependency
  // in `useMemo` below.
  const [isRendered, setIsRendered] = useState<boolean>(false);

  const frameTreeSvelte = useMemo(() => {
    const target = containerRef.current;
    if (!target || !isRendered) return;
    // clear the container before rendering
    // when switching between traces
    target.innerHTML = "";
    return new FrameTreeSvelte({
      target: containerRef.current,
      props: {
        frame: vizTree,
        height: containerRef.current.clientHeight,
        width: containerRef.current.clientWidth,
      },
    });
  }, [vizTree, isRendered]);

  useEffect(() => {
    frameTreeSvelte?.$$set?.({
      focusFrameIndex: Array.from(hovered.frameIndexes)[0],
    });
  }, [frameTreeSvelte, hovered.frameIndexes]);

  useEffect(() => {
    if (!frameTreeSvelte) return;
    // pass the focus frame immediately onto the component in order to make tooltips work
    frameTreeSvelte.$on("frameFocus", (event) => {
      if (event.detail) {
        if (event.detail.type === "frame_span") {
          onHover({
            functionId: getFunctionId(event.detail),
            frameIndexes: new Set([getFrameIndex(event.detail)]),
            preview: true,
          });
        } else {
          onHover({
            functionId: undefined,
            frameIndexes: new Set([getFrameIndex(event.detail)]),
          });
        }
      } else {
        onHover({});
      }
    });
    frameTreeSvelte.$on("frameClick", async (event) => {
      const frame = event.detail;
      if (!frame) return;
      if (frame.type === "frame_span") {
        onSelect({
          functionId: getFunctionId(frame),
          frameIndexes: new Set([getFrameIndex(frame)]),
        });
        const [path, startLineStr] = frame.data.call_frame.path.split(":");
        if (path === "<exec>") {
          // Do not console.log if the code is visible in the editor
          return;
        }
        const [, endLineStr] = frame.data.return_frame.path.split(":");
        const startLine = parseInt(startLineStr);
        const endLine = parseInt(endLineStr);
        const fileContent = await readFile(path);
        const code = fileContent
          .split(/\r?\n/g)
          // The code always starts at startLine - 1.
          // The return statement starts at endLine - 1, but it can be multiple lines long
          // and currently we don't take this into account.
          .slice(startLine - 1, endLine)
          .join("\n");
        console.log(code);
      }
    });
  }, [frameTreeSvelte, onHover, onSelect, readFile]);

  useEffect(() => setIsRendered(true), []);

  return <div id="viz" className="w-full h-full" ref={containerRef}></div>;
};

export default Visualization;
