import React from "react";
import MonacoEditor from "@monaco-editor/react";

import { toPython } from "../../../extension/toPython";

const RawView = ({ raw }: { raw: any }) => (
  <MonacoEditor
    value={toPython(raw, true)}
    defaultLanguage="python"
    options={{
      readOnly: true,
      readOnlyMessage: {
        value: "Read-only mode: it's not possible edit the raw Kolo trace.",
      },
    }}
    height="100%"
  />
);

export default RawView;
