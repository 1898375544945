import React, { useCallback, useEffect, useRef } from "react";
import MonacoEditor, { OnMount } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import ReactDOM from "react-dom/client";

type ViewZoneId = string;
type ViewZoneMonacoId = string;

interface ViewZoneProps {
  /** the id is used like a key, to match the viewzone to the React element */
  id: ViewZoneId;
  afterLineNumber: number;
  heightInLines: number;
  children: React.ReactNode;
}

interface Item {
  viewZone: monaco.editor.IViewZone;
  viewZoneMonacoId: ViewZoneMonacoId;
  overlayWidget: monaco.editor.IOverlayWidget;
  overlayDomNode: HTMLDivElement;
  reactRoot: ReactDOM.Root;
}

export const ViewZone: React.FC<ViewZoneProps> = () => {
  return null;
};

const Editor = ({
  onChange,
  defaultCode,
  children,
}: {
  defaultCode: string;
  onChange?: (code: string) => void;
  children?:
    | React.ReactElement<ViewZoneProps>
    | React.ReactElement<ViewZoneProps>[];
}) => {
  const editorRef = useRef<Parameters<OnMount>[0] | null>(null);
  const itemsRef = useRef<Record<string, Item>>({});

  const onMount: OnMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  useEffect(() => {
    // Do nothing if editor is not ready
    if (!editorRef.current) return;

    const editor = editorRef.current;
    const items = itemsRef.current;
    const seenIds = new Set<string>();
    // extract the position where the code starts
    // (padding out line numbers and folding controls)
    const { contentLeft } = editor.getLayoutInfo();

    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child) || child.type !== ViewZone) {
        throw new Error(`Unexpected child of Editor: ${child?.type}`);
      }

      const { id, afterLineNumber, heightInLines, children } = child.props;

      if (seenIds.has(id)) {
        throw new Error(`A child of Editor has a non-unique key: ${id}`);
      } else {
        seenIds.add(id);
      }

      if (items[id]) {
        // View zone already exists, update its properties
        const { viewZone, viewZoneMonacoId, overlayDomNode, reactRoot } =
          items[id];
        if (
          viewZone.afterLineNumber !== afterLineNumber ||
          viewZone.heightInLines !== heightInLines
        ) {
          viewZone.afterLineNumber = afterLineNumber;
          viewZone.heightInLines = heightInLines;
          editor.changeViewZones((changeAccessor) => {
            changeAccessor.layoutZone(viewZoneMonacoId);
          });
        }
        overlayDomNode.style.paddingLeft = `${contentLeft}px`;
        reactRoot.render(children);
      } else {
        // Create a new view zone
        const domNode = document.createElement("div");
        const overlayDomNode = document.createElement("div");
        const viewZone: monaco.editor.IViewZone = {
          afterLineNumber,
          heightInLines,
          domNode,
          onDomNodeTop(top) {
            overlayDomNode.style.top = `${top}px`;
          },
          onComputedHeight: (height) => {
            overlayDomNode.style.height = `${height}px`;
          },
        };
        const overlayWidget: monaco.editor.IOverlayWidget = {
          getDomNode() {
            return overlayDomNode;
          },
          getId() {
            return id;
          },
          getPosition() {
            return null;
          },
        };
        const reactRoot = ReactDOM.createRoot(overlayDomNode);

        editor.changeViewZones((changeAccessor) => {
          // add the view zone
          const viewZoneMonacoId: ViewZoneMonacoId =
            changeAccessor.addZone(viewZone);
          // add the overlay widget
          editor.addOverlayWidget(overlayWidget);
          overlayDomNode.style.paddingLeft = `${contentLeft}px`;
          // render the overlay widget
          reactRoot.render(children);
          // save variables for the update step
          items[id] = {
            viewZone,
            viewZoneMonacoId,
            overlayWidget,
            overlayDomNode,
            reactRoot,
          };
        });
      }
    });

    // Remove view zones that are no longer in the React render tree
    const removedIds = Object.keys(items).filter((key) => !seenIds.has(key));
    for (const id of removedIds) {
      const { viewZoneMonacoId, overlayWidget } = items[id];
      editor.changeViewZones((changeAccessor) => {
        changeAccessor.removeZone(viewZoneMonacoId);
      });
      editor.removeOverlayWidget(overlayWidget);
      delete items[id];
    }
  }, [children]);

  return (
    <>
      <MonacoEditor
        onChange={(value) => {
          onChange?.(value || "");
        }}
        onMount={onMount}
        defaultValue={defaultCode}
        defaultLanguage="python"
        options={{ minimap: { enabled: false } }}
        height="100%"
      />
    </>
  );
};

export default Editor;
