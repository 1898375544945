import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Playground from "./Playground";
import { PyodideProvider } from "../pyodide/react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Playground />,
  },
]);

const App = () => {
  return (
    <PyodideProvider>
      <RouterProvider router={router} />
    </PyodideProvider>
  );
};

export default App;
