import React from "react";
import clsx from "clsx";
import Wrapper from "./Wrapper";

export type TabProps = {
  readonly id: string;
  readonly title: string;
};

const Tabs = <Tab extends TabProps>({
  tabs,
  selectedId,
  onSelect,
}: {
  tabs: readonly Tab[];
  onSelect: (tab: Tab) => void;
  selectedId?: Tab["id"];
}) => {
  return (
    <Wrapper className="border-b border-gray-300 overflow-x-auto scrollbar-hide">
      <div className="flex">
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            tab={tab}
            isSelected={selectedId === tab.id}
            onSelect={onSelect}
          />
        ))}
      </div>
    </Wrapper>
  );
};

const Tab = <Tab extends TabProps>({
  tab,
  onSelect,
  isSelected,
}: {
  tab: Tab;
  onSelect: (tab: Tab) => void;
  isSelected: boolean;
}) => {
  return (
    <button
      key={tab.id}
      className={clsx(
        "py-1 mr-5 border-b-[3px] text-left whitespace-nowrap hover:text-gray-900",
        isSelected
          ? "border-blue-500 text-gray-900"
          : "border-transparent text-gray-500"
      )}
      onClick={() => onSelect(tab)}
    >
      <h3 className="text-[13px] font-medium text-inherit">{tab.title}</h3>
    </button>
  );
};

export default Tabs;
