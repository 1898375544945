import { Example } from "../components/Examples";

import fibonacciCode from "../examples/fibonacci.py";
import mergeSortCode from "../examples/merge_sort.py";
import pandasCode from "../examples/pandas.py";
import whackAMoleCode from "../examples/whack_a_mole.py";

const examples: Example[] = [
  {
    name: "fibonacci",
    internalId: "fibonacci",
    title: "Fibonacci",
    code: fibonacciCode,
  },
  {
    name: "merge-sort",
    internalId: "merge-sort",
    title: "Merge sort",
    code: mergeSortCode,
  },
  {
    name: "pandas",
    internalId: "pandas",
    title: "Pandas library",
    code: pandasCode,
  },
  {
    name: "whack-a-mole",
    internalId: "whack-a-mole",
    title: "Whack-a-mole",
    code: whackAMoleCode,
  },
];

export default examples;
