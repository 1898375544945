import "tippy.js/dist/tippy.css";
import { default as tippy, type SingleTarget, type Props } from "tippy.js";

export default function (target: SingleTarget, props: Partial<Props>) {
  const tippyInstance = tippy(target, props);
  return {
    update(newProps: Partial<Props>) {
      tippyInstance.setProps(newProps);
    },
    destroy() {
      tippyInstance.destroy();
    },
  };
}
