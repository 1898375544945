import React from "react";
import PaneHeader from "./PaneHeader";

const ScrollablePane = ({
  children,
  header,
  scrollingContainerRef,
}: React.PropsWithChildren<{
  header?: JSX.Element;
  scrollingContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
}>) => {
  return (
    <div className="w-full h-full flex flex-col">
      {header && <PaneHeader>{header}</PaneHeader>}
      <div className="flex-1 relative">
        <div
          className="absolute top-0 right-0 bottom-0 left-0 overflow-auto"
          ref={scrollingContainerRef}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScrollablePane;
