import React from "react";
import clsx from "clsx";

const PaneHeader = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>) => {
  return (
    <div
      className={clsx(
        "px-2 py-[2px] bg-gray-100 border-b border-gray-200 text-gray-600 font-normal text-[13px] whitespace-nowrap",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default PaneHeader;
