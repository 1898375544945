import type { HierarchyPointLink } from "d3-hierarchy";
import type { VizTreeNode } from "./types";

export function getNodeColor(
  node: VizTreeNode,
  focusNode?: VizTreeNode
): string {
  if (focusNode !== undefined && node.index_call > focusNode.index_return) {
    return "var(--vscode-list-hoverBackground)";
  } else if (node.type === "root") {
    // should never be shown
    return "red";
  } else if (node.type === "sql_query") {
    return "var(--vscode-charts-purple)";
  } else if (node.type === "nested_background_job") {
    return "var(--vscode-charts-green)";
  } else if (node.type === "outbound_http_request") {
    return "var(--vscode-charts-blue)";
  } else if (node.type === "django_template") {
    return "var(--vscode-charts-orange)";
  } else if (
    node.data.return_frame?.annotatedExceptionMessage ||
    node.data.return_frame?.annotated500Exception ||
    ("exception" in node.data.return_frame && node.data.return_frame.exception)
  ) {
    return "var(--vscode-errorForeground)";
  } else if (
    node.data.return_frame.path.includes("requests/sessions.py") ||
    node.data.return_frame.path.includes("urllib3/connectionpool.py")
  ) {
    return "var(--vscode-charts-blue)";
  } else if (
    node.data.return_frame.path.includes("celery") &&
    node.data.return_frame.co_name.includes("apply_async")
  ) {
    return "var(--vscode-charts-green)";
  }
  return "var(--vscode-foreground)";
}

export function getEdgeColor(
  link: HierarchyPointLink<VizTreeNode>,
  focusNode?: VizTreeNode
): string {
  return getNodeColor(link.target.data, focusNode);
}

export function getNodeName(node: VizTreeNode): string {
  if (node.type === "frame_span") {
    return node.data.return_frame.co_name;
  } else if (node.type === "sql_query") {
    return node.data.query;
  } else if (node.type === "outbound_http_request") {
    return node.data.request.method_and_full_url;
  } else if (node.type === "nested_background_job") {
    return node.data.name;
  } else if (node.type === "django_template") {
    return node.data.template;
  } else if (node.type === "root") {
    return "";
  } else {
    // exhaustive check
    const _: never = node;
    return _;
  }
}
