import clsx from "clsx";
import React from "react";

const Wrapper = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div className={clsx("px-3", className)} {...props}>
      {children}
    </div>
  );
};

export default Wrapper;
