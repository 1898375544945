import type { RecordedLogMessage } from "./model";

export function getLogMsgLabel(log: RecordedLogMessage): string {
  if (typeof log.msg === "string") return log.msg;
  try {
    // We cannot anticipate any of the fields in the message, so we are
    // stringifying all of it.
    return JSON.stringify(Object.fromEntries(log.msg));
  } catch (error) {
    // Fall back to logging the level only.
    return `log ${log.level.toUpperCase()}`;
  }
}
