<script lang="ts">
  import type { HierarchyPointLink, HierarchyPointNode } from "d3-hierarchy";
  import type { Voronoi } from "d3-delaunay";
  import type { ScalePower } from "d3-scale";
  import type { ZoomTransform, D3ZoomEvent } from "d3-zoom";
  import { scaleSqrt } from "d3-scale";
  import { linkRadial, pointRadial } from "d3-shape";
  import { Delaunay } from "d3-delaunay";
  import { select } from "d3-selection";
  import { zoom } from "d3-zoom";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import escape from "escape-html";

  import type { VizTreeNode, FrameHierarchyPlot } from "../util/types";
  import { getFrameIndex, treeFromFrame, type FrameIndex } from "../util/frames";
  import tippy from "../util/tippy";
  import { getEdgeColor, getNodeColor } from "../util/color";
  import { toPython } from "../../../extension/toPython";

  export let frame: VizTreeNode;
  export let width: number;
  export let height: number;

  export let focusFrameIndex: FrameIndex | undefined = undefined;

  const TOOLTIP_MAX_HOVER_DISTANCE_PX = 25;

  const dispatch = createEventDispatcher<{
    frameClick: VizTreeNode | undefined;
    frameFocus: VizTreeNode | undefined;
  }>();

  const createLinkPath = linkRadial<
    HierarchyPointLink<VizTreeNode>,
    HierarchyPointNode<VizTreeNode>
  >()
    .angle((d) => d.x)
    .radius((d) => d.y);

  let radius: number;
  $: radius = width / 2;

  let frameTree: FrameHierarchyPlot;
  $: frameTree = treeFromFrame(frame, [Math.PI, radius]);

  let edges: HierarchyPointLink<VizTreeNode>[];
  $: edges = frameTree
    .links()
    // filter out edges from the fake root node
    .filter((edge) => edge.source.data.type !== "root");

  let nodes: FrameHierarchyPlot[];
  $: nodes = frameTree
    .descendants()
    // filter out the fake root node
    .filter((node) => node.data.type !== "root");

  let focusNode: FrameHierarchyPlot | undefined;
  $: focusNode = nodes.find((node) => getFrameIndex(node.data) === focusFrameIndex);

  let edgeWidthScale: ScalePower<number, number, number>;
  $: edgeWidthScale = scaleSqrt(
    [0, frameTree.data.all_children_count],
    [1.5, 6]
  );

  let nodeRadiusScale: ScalePower<number, number, number>;
  $: nodeRadiusScale = scaleSqrt(
    [0, frameTree.data.all_children_count],
    [1.5, 8]
  );

  let svgElement: SVGSVGElement | undefined;
  let zoomTransform: ZoomTransform | undefined;
  let bBox: DOMRect | undefined;

  function updateBoundingBox() {
    bBox = svgElement!.getBBox();
  }

  let voronoi: Voronoi<FrameHierarchyPlot> | undefined;
  $: voronoi = bBox
    ? Delaunay.from(nodes.map((d) => pointRadial(d.x, d.y))).voronoi([
        bBox.x,
        bBox.y,
        bBox.x + bBox.width,
        bBox.y + bBox.height,
      ])
    : undefined;

  function onZoom(event: D3ZoomEvent<Element, unknown>) {
    zoomTransform = event.transform;
  }

  onMount(async () => {
    // wait for all elements to render before updating bounding box
    await tick();
    updateBoundingBox();
    if (svgElement) {
      select(svgElement as Element).call(zoom().on("zoom", onZoom));
    }
  });

  function onFocus(node: FrameHierarchyPlot) {
    dispatch("frameFocus", node.data);
  }

  function onBlur() {
    dispatch("frameFocus", undefined);
  }

  function onClick(node: FrameHierarchyPlot | undefined) {
    dispatch("frameClick", node?.data);
  }

  let tippyContainerRef: HTMLElement;

  function getTooltipHTML(node: VizTreeNode): string {
    if (node.type === "root") {
      return "";
    } else if (node.type === "sql_query") {
      return `
        <div class="tooltip-content">
          <div class="return-arg">${escape(node.data.query)}</div>
        </div>
      `;
    } else if (node.type === "outbound_http_request") {
      const { request, response } = node.data;
      let tooltipContent = `
      <div class="tooltip-content mono no-wrap">
        <div class="text-md">${request.method}</div>
        <div class="font-bold">${request.url}</div>
      `;

      if (request.body) {
        tooltipContent += `
          <div class="text-md">${escape(request.body)}</div>
        `;
      }
      if (response) {
        tooltipContent += `
          <br />
          <div class="text-md">${response.status_code}</div>
        `;

        if (response.body) {
          tooltipContent += `
          <div class="text-md">${escape(response.body)}</div>
        `;
        }
      }

      tooltipContent += "\n</div>";
      return tooltipContent;
    } else if (node.type === "nested_background_job") {
      return `
      <div class="tooltip-content mono no-wrap">
        <div class="text-md">${node.data.subtype} job</div>
        <div class="font-bold">${node.data.name}</div>
      </div>`;
    } else if (node.type === "django_template") {
      return `
      <div class="tooltip-content mono no-wrap">
        <div class="text-md">Django Template</div>
        <div class="font-bold">${node.data.template}</div>
      </div>`;
    } else {
      const [filePath, lineNumber] = node.data.return_frame.path.split(":");
      const { co_name } = node.data.return_frame;
      return `
        <div class="tooltip-content">
          <div class="file-path-and-line-number">
            <span class="file-path">${filePath}</span><span class="line-number">:${lineNumber}</span>
          </div>
          <div class="co-name">${escape(co_name)}</div>
          <div class="return-arg">return: ${escape(
            toPython(node.data.return_frame.arg)
          )}</div>
        </div>
      `;
    }
  }
</script>

<main bind:this={tippyContainerRef}>
  <svg
    bind:this={svgElement}
    {width}
    {height}
    viewBox={bBox
      ? `${bBox.x}, ${bBox.y}, ${bBox.width}, ${bBox.height}`
      : undefined}
  >
    <!-- Top-level group, used for zoom transforms -->
    <g
      transform={zoomTransform
        ? `translate(${zoomTransform.x}, ${zoomTransform.y}) scale(${zoomTransform.k})`
        : ""}
    >
      <!-- Edges -->
      <g>
        {#each edges as edge}
          <path
            fill="none"
            stroke-width={edgeWidthScale(edge.target.data.all_children_count)}
            stroke-opacity={0.6}
            style={`stroke: ${getEdgeColor(edge, focusNode?.data)};`}
            d={createLinkPath(edge) || undefined}
          />
        {/each}
      </g>

      <!-- Focus node -->
      {#if focusNode}
        <circle
          transform="rotate({(focusNode.x * 180) / Math.PI -
            90}) translate({focusNode.y}, 0)"
          r={nodeRadiusScale(focusNode.data.all_children_count) + 2}
          fill="none"
          stroke-width={3}
          stroke-opacity={0.8}
          style="stroke: var(--vscode-list-focusOutline);"
          use:tippy={{
            appendTo: tippyContainerRef,
            content: getTooltipHTML(focusNode.data),
            allowHTML: true,
            hideOnClick: false,
            showOnCreate: true,
          }}
        />
      {/if}

      <!-- Nodes -->
      <g>
        {#each nodes as node}
          <circle
            transform="rotate({(node.x * 180) / Math.PI -
              90}) translate({node.y}, 0)"
            r={nodeRadiusScale(node.data.all_children_count)}
            style={`fill: ${getNodeColor(node.data, focusNode?.data)}`}
          />
        {/each}
      </g>

      <!-- Voronoi polygons (for finding closest node) -->
      {#if voronoi}
        <g>
          {#each nodes as node, index}
            <defs>
              <clipPath id={`voronoi-clip-path-${index}`}>
                <circle
                  transform="rotate({(node.x * 180) / Math.PI -
                    90}) translate({node.y}, 0)"
                  r={TOOLTIP_MAX_HOVER_DISTANCE_PX}
                  style={`fill: ${getNodeColor(node.data, focusNode?.data)}`}
                />
              </clipPath>
            </defs>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <!-- svelte-ignore a11y-mouse-events-have-key-events -->
            <path
              fill="rgba(0,0,0,0)"
              d={voronoi.renderCell(index)}
              clip-path={`url(#voronoi-clip-path-${index})`}
              pointer-events="all"
              style="cursor: pointer"
              on:mouseover={() => onFocus(node)}
              on:mouseout={() => onBlur()}
              on:click={() => onClick(node)}
            />
          {/each}
        </g>
      {/if}
    </g>
  </svg>
</main>

<style>
  svg {
    display: block;
    margin: 0;
    padding: 0;
  }

  :global(.tooltip-content) {
    font-size: --vscode-editor-font-size;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.file-path) {
    opacity: 0.7;
  }

  :global(.line-number) {
    opacity: 0.5;
  }

  :global(.co-name) {
    font-family: monospace;
    font-weight: 700;
    margin-bottom: 0.25em;
  }

  :global(.mono) {
    font-family: monospace;
  }

  :global(.text-md) {
    font-size: 80%;
  }

  :global(.no-wrap) {
    white-space: nowrap;
  }

  :global(.font-bold) {
    font-weight: 700;
  }

  :global(.call-arg, .return-arg) {
    font-family: monospace;
    font-size: 80%;
  }

  :global(.call-arg, .return-arg) {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  :global(.tippy-box) {
    color: var(--vscode-editor-foreground);
    background-color: var(--vscode-dropdown-background);
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.05);
    padding: 10px;
    opacity: 0.96;
  }

  :global(.tippy-box[data-placement^="top"] > .tippy-arrow::before) {
    border-top-color: var(--vscode-dropdown-background);
  }

  :global(.tippy-box[data-placement^="bottom"] > .tippy-arrow::before) {
    border-bottom-color: var(--vscode-dropdown-background);
  }

  :global(.tippy-box[data-placement^="left"] > .tippy-arrow::before) {
    border-left-color: var(--vscode-dropdown-background);
  }

  :global(.tippy-box[data-placement^="right"] > .tippy-arrow::before) {
    border-right-color: var(--vscode-dropdown-background);
  }

  :global(.tippy-box > .tippy-backdrop) {
    background-color: var(--vscode-dropdown-background);
  }

  :global(.tippy-box > .tippy-svg-arrow) {
    fill: var(--vscode-dropdown-background);
  }
</style>
