import type { TextDocument } from "vscode";
import type { Frame } from "./frames-of-interest-model";

import type { ExecutionTreeNode, FrameSpan } from "./model";

interface LineAndPath {
  line: number;
  path: string;
}

export function getLineNumberAndPath(frame: Frame): LineAndPath {
  const pathWithLineNumber = frame.path;

  const [path, line] = pathWithLineNumber.split(":");

  return { path, line: parseInt(line) };
}

export function getLineCount(document: string): number {
  return (String(document).match(/\n/g) || "").length + 1;
}

export function findRelevantFrames(
  frames: Frame[],
  document: TextDocument
): Frame[] {
  const relevantFrames: Frame[] = [];
  const fileName = document.fileName;
  frames.forEach((frame) => {
    const { path } = getLineNumberAndPath(frame);
    // TODO: Make this more sophisticated in the future. endsWith might not always be exactly right
    if (fileName.endsWith(path)) {
      relevantFrames.push(frame);
    }
  });

  return relevantFrames;
}

export function justFrameSpans(treeNodes: ExecutionTreeNode[]): FrameSpan[] {
  return treeNodes
    .map((node) => {
      if (node.type !== "frame_span") {
        return justFrameSpans(node.children);
      }

      const children = justFrameSpans(node.children);
      return {
        index: node.index,
        name: node.name,
        children: children,
        // The all_children_count here are wrong. But that's OK for now..
        all_children_count: node.all_children_count,
        call_frame: node.data.call_frame,
        return_frame: node.data.return_frame,
      };
    })
    .flat();
}
