import React, { useCallback, useMemo, useState } from "react";
import { PanelGroup, Panel } from "react-resizable-panels";

import { usePyodide } from "../pyodide/react";
import Visualization from "./Visualization";
import Tabs, { TabProps } from "./Tabs";
import { FunctionWithFrameSpans } from "../utils/parseTrace";
import {
  PanelResizeHandleHorizontal,
  PanelResizeHandleVertical,
} from "./PanelResizeHandle";
import { FunctionsPane } from "./Functions";
import { CallsPane } from "./Calls";
import RawView from "./RawView";
import { VizRootNode } from "../../../view/app/util/types";
import { OnSelectionState, SelectionState } from "../utils/types";

const tabs = [
  { id: "visualization", title: "Visualization" },
  { id: "raw", title: "Raw" },
] as const satisfies readonly TabProps[];

type Tab = (typeof tabs)[number];
type TabId = Tab["id"];

function emptySelectionState(): SelectionState {
  return {
    functionId: undefined,
    frameIndexes: new Set(),
  };
}

const Preview = ({
  functions,
  vizTree,
  msgpack,
}: {
  functions?: FunctionWithFrameSpans[];
  vizTree?: VizRootNode;
  msgpack?: Map<any, any>;
}) => {
  const { error, loading } = usePyodide();
  const [tabId, setTabId] = useState<TabId>("visualization");
  const onTabSelect = useCallback((tab: Tab) => setTabId(tab.id), [setTabId]);

  const [selectionState, setSelectionState] = useState<SelectionState>(
    emptySelectionState()
  );
  const onSelectionState: OnSelectionState = useCallback(
    (state) =>
      setSelectionState({
        functionId: state.functionId,
        frameIndexes: state.frameIndexes ?? new Set(),
      }),
    []
  );

  const [hoverState, setHoverState] = useState<SelectionState>(
    emptySelectionState()
  );
  const onHoverState: OnSelectionState = useCallback(
    (state) =>
      setHoverState({
        functionId: state.functionId,
        frameIndexes: state.frameIndexes ?? new Set(),
        preview: state.preview ?? false,
      }),
    []
  );

  const callsPaneProps = useMemo(
    () => ({
      isPreview:
        (hoverState.preview ?? false) &&
        hoverState.functionId !== undefined &&
        hoverState.functionId !== selectionState.functionId,
      functions:
        hoverState.preview && hoverState.functionId !== undefined
          ? functions?.find((fn) => fn.id === hoverState.functionId)
          : functions?.find((fn) => fn.id === selectionState.functionId),
    }),
    [
      functions,
      hoverState.functionId,
      hoverState.preview,
      selectionState.functionId,
    ]
  );

  return (
    <div className="relative flex-1 flex flex-col">
      {loading && (
        <div className="flex py-6 items-center justify-center absolute z-10 top-0 right-0 bottom-0 left-0 overflow-auto bg-white/40">
          <div>
            <Loading />
          </div>
        </div>
      )}
      {error && (
        <div className="flex py-6 items-center justify-center absolute z-10 top-0 right-0 bottom-0 left-0 overflow-auto bg-white/70">
          <div className="px-3 py-2 rounded bg-red-100/90 text-red-800 font-mono text-xs whitespace-pre-wrap">
            {error?.message}
          </div>
        </div>
      )}
      <div className="bg-gray-100">
        <Tabs tabs={tabs} onSelect={onTabSelect} selectedId={tabId} />
      </div>
      <div className="flex-1 relative">
        {tabId === "raw" ? (
          <div className="absolute top-0 right-0 bottom-0 left-0 overflow-auto">
            <RawView raw={msgpack} />
          </div>
        ) : (
          <PanelGroup
            direction="vertical"
            className="absolute top-0 right-0 bottom-0 left-0 overflow-auto"
          >
            <Panel id="visualization" defaultSize={50} className="relative">
              <div className="absolute top-0 right-0 bottom-0 left-0 overflow-auto">
                {tabId === "visualization" && vizTree ? (
                  <Visualization
                    vizTree={vizTree}
                    selected={selectionState}
                    onSelect={onSelectionState}
                    hovered={hoverState}
                    onHover={onHoverState}
                  />
                ) : undefined}
              </div>
            </Panel>
            <PanelResizeHandleHorizontal />
            <Panel id="info" defaultSize={30} className="relative">
              <PanelGroup
                direction="horizontal"
                className="absolute top-0 right-0 bottom-0 left-0 overflow-auto"
              >
                <Panel id="functions" className="relative">
                  <div className="absolute top-0 right-0 bottom-0 left-0 overflow-auto">
                    <FunctionsPane
                      functions={functions}
                      selected={selectionState}
                      onSelect={onSelectionState}
                      hovered={hoverState}
                      onHover={onHoverState}
                    />
                  </div>
                </Panel>
                <PanelResizeHandleVertical />
                <Panel id="calls" className="relative">
                  <div className="absolute top-0 right-0 bottom-0 left-0 overflow-auto">
                    <CallsPane
                      {...callsPaneProps}
                      selected={selectionState}
                      onSelect={onSelectionState}
                      hovered={hoverState}
                      onHover={onHoverState}
                    />
                  </div>
                </Panel>
              </PanelGroup>
            </Panel>
          </PanelGroup>
        )}
      </div>
    </div>
  );
};

const Loading = () => (
  <svg
    className="animate-spin -ml-1 mr-3 h-10 w-10 text-black"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export default Preview;
