import { useCallback, useMemo } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import entries from "./entries";

// TODO: pass all params to all parsers, so that we can do URL migrations
// easily, like `code` => `project` param.
const parsersByName = {
  code: (param: string) => window.atob(param),
  example: (param: string) => param,
  hideCta: (param: string): boolean => !!param,
} as const satisfies Record<string, (param: string) => any>;

type Serializers = {
  [K in keyof typeof parsersByName]: (
    param: ReturnType<(typeof parsersByName)[K]>
  ) => string;
};

const serializersByName: Serializers = {
  code: (param: string) => window.btoa(param),
  example: (param: string) => param,
  hideCta: (param: boolean) => param.toString(),
};

type ParsedQueryParams = Partial<{
  -readonly [K in keyof typeof parsersByName]: ReturnType<
    (typeof parsersByName)[K]
  >;
}>;

function parseQueryParams(searchParams: URLSearchParams): ParsedQueryParams {
  const queryParams: ParsedQueryParams = {};

  for (const [name, parse] of entries(parsersByName)) {
    const value = searchParams.get(name);
    if (value === null) continue;
    const parsedValue = parse(value);
    if (parsedValue !== undefined) queryParams[name] = parsedValue as any;
  }

  return queryParams;
}

// TODO: drop query params we don't recognise.
// This enables URL migrations to work better (e.g. moving from `code` to the
// `project` query param), by applying the migration only once.
function serializeQueryParams(
  queryParams: ParsedQueryParams,
  searchParams: URLSearchParams = new URLSearchParams()
): URLSearchParams {
  for (const [key, value] of Object.entries(queryParams)) {
    if (value === undefined) {
      searchParams.delete(key);
      continue;
    }
    if (key in serializersByName) {
      // force type fix
      const serializerKey: keyof typeof serializersByName = key as any;
      const serializedValue = serializersByName[serializerKey](value as never);
      if (serializedValue !== undefined) searchParams.set(key, serializedValue);
    } else {
      searchParams.set(key, value as string);
    }
  }
  return searchParams;
}

export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo<ParsedQueryParams>(
    () => parseQueryParams(searchParams),
    [searchParams]
  );

  const setQueryParams = useCallback(
    (params: ParsedQueryParams, navigateOpts?: NavigateOptions) => {
      const newSearchParams = serializeQueryParams(params);
      setSearchParams(newSearchParams, navigateOpts);
    },
    [setSearchParams]
  );

  const updateQueryParams = useCallback(
    (params: ParsedQueryParams, navigateOpts?: NavigateOptions) => {
      setSearchParams(
        (oldParams) => serializeQueryParams(params, oldParams),
        navigateOpts
      );
    },
    [setSearchParams]
  );

  return { queryParams, setQueryParams, updateQueryParams } as const;
}
