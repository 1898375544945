import React from "react";
import ReactDOM from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";

import App from "./components/App";

import "./index.css";
import "./vscode.css";

interface KoloBoot {
  options: KoloBootOptions;
}

interface KoloBootOptions {
  /**
   * The URL which all SPA requests will want to use as a base; this may be different to
   * the mountPoint to accomodate different URL namespaces.
   *
   * For example, you might wish to mount the SPA on a domain of `beta.example.com`
   * but have it talk to the live API on `kolo.com/beta/api/` instead. */
  baseUrl: URL;
  /**
   * The web URL/subfolder from which the SPA bootstraps. This is ostensibly a sink, insofar as
   * all requests to anything under said URL/subfolder should ultimately deep-link to the specific thing
   * for the purposes of sharing specific traces by URL, at least in the hosted version.
   */
  mountPoint: string;
  /** If island is true, the app is being displayed as an island/portlet/SPA within a larger web viewport
  (e.g. it's the hosted browser, rather than the embedded Django middleware ...) */
  island: boolean;
  /** This is just a flag so that <i>other</i> functionality could infer things and swap
   * behaviours as needed. Generally speaking, anywhere `island` is `true` then local
   * is <i>probably</i> `false` */
  local: boolean;
}

declare global {
  interface Window {
    kolo: KoloBoot;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <PostHogProvider
    apiKey={"phc_zQM74CuK5pp8UqgLvLlUF1yU38gp4XUAw9JLfp03fK3"}
    options={{
      api_host: "https://events.kolo.app",
      person_profiles: "always",
      ip: false,
    }}
  >
    <App />
  </PostHogProvider>
  // </React.StrictMode>
);
