import { makeExecutionTree } from "./build-frame-tree";
import type {
  ExecutionTreeInfo,
  FrameOfInterest,
} from "./frames-of-interest-model";
import type { Invocation, InvocationID } from "./model";
import {
  type ProcessedNode,
  ServedRequestNode,
  makeProcessedNode,
} from "./node";

export class ProcessedTrace {
  public readonly id: InvocationID;
  // The "main" tree which for now is the tree of frames
  // from the thread that was active when kolo was enabled
  public readonly main_tree: ProcessedTree;
  public readonly threads: ProcessedTree[];
  public readonly timestamp: number;
  constructor(public readonly unprocessed: Invocation) {
    this.id = unprocessed.trace_id;
    const mainFramesOfInterest =
      ProcessedTrace.getMainFramesOfInterest(unprocessed);
    this.main_tree = new ProcessedTree(mainFramesOfInterest, this.id);
    this.threads = [];
    this.timestamp = unprocessed.timestamp;
  }

  get isSimpleServedRequest(): boolean {
    return (
      this.main_tree.rootNodes.length === 1 &&
      this.main_tree.rootNodes[0] instanceof ServedRequestNode
    );
  }

  get name(): string {
    const firstNode = this.main_tree.rootNodes[0];
    if (this.isSimpleServedRequest && firstNode instanceof ServedRequestNode) {
      // For a request, show the method & path
      const request = firstNode.data.request;
      return `${request?.method} ${request?.path_info}`;
    } else if (firstNode) {
      // If we have a root node, show its name
      return `${firstNode.name} and ${
        this.main_tree.totalExecutionTreeNodeCount - 1
      } other calls`;
    } else {
      // If we don't have any nodes, return the trace ID
      return this.id;
    }
  }

  static getMainFramesOfInterest(unprocessed: Invocation): FrameOfInterest[] {
    if (
      unprocessed.meta.config?.use_monitoring &&
      unprocessed.meta.config.use_monitoring === true
    ) {
      const current_thread_id = unprocessed.current_thread_id;
      if (!current_thread_id) {
        throw new Error("use_monitoring is true but no current thread id");
      }
      const thread = unprocessed.threads[current_thread_id];
      if (thread) {
        return thread.frames;
      } else {
        // We have a current thread id, but no frame from the thread has been captured yet.
        return [];
      }
    }

    return unprocessed.frames_of_interest;
  }
}

export class ProcessedTree {
  public readonly traceId: InvocationID;
  public readonly basicExecutionTree: ExecutionTreeInfo;
  public readonly rootNodes: ProcessedNode[];
  public readonly totalExecutionTreeNodeCount: number;

  constructor(framesOfInterest: FrameOfInterest[], traceId: InvocationID) {
    this.traceId = traceId;
    this.basicExecutionTree = makeExecutionTree(framesOfInterest);
    this.rootNodes = this.basicExecutionTree.executionTreeNodes.map((node) =>
      makeProcessedNode(node, null, traceId)
    );
    this.totalExecutionTreeNodeCount =
      this.basicExecutionTree.totalExecutionTreeNodeCount;
  }

  public *dfs(): Generator<ProcessedNode> {
    for (const rootNode of this.rootNodes) {
      yield* rootNode.dfs();
    }
  }

  public findNodeByIndex(index: number): ProcessedNode | null {
    for (const node of this.dfs()) {
      if (node.index === index) {
        return node;
      }
    }
    return null;
  }
}
