import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import clsx from "clsx";

import { FunctionWithFrameSpans } from "../utils/parseTrace";
import ScrollablePane from "./ScrollablePane";
import { OnSelectionState, SelectionState } from "../utils/types";

const FunctionBlock = ({
  fn,
  isSelected,
  isHovered,
  onSelect,
  onHover,
  elementRef,
}: {
  fn: FunctionWithFrameSpans;
  isSelected: boolean;
  isHovered: boolean;
  onSelect: OnSelectionState;
  onHover: OnSelectionState;
  elementRef?: React.Ref<HTMLDivElement>;
}) => {
  const modulePath = fn.name.split(".");
  const prefix = modulePath
    .slice(0, -1)
    .map((n) => `${n}.`)
    .join("");
  const name = modulePath[modulePath.length - 1];
  const selectedState = useMemo(() => ({ functionId: fn.id }), [fn.id]);
  const onClick = useCallback(
    () => onSelect(selectedState),
    [onSelect, selectedState]
  );
  const onMouseEnter = useCallback(
    () => onHover(selectedState),
    [onHover, selectedState]
  );
  const onMouseLeave = useCallback(() => onHover({}), [onHover]);
  const args = fn.frameSpans[0].data.call_frame.locals as Record<string, any>;
  return (
    <div
      className={clsx(
        "width-full grid grid-cols-[1fr,min-content] px-2 py-1 border-b cursor-pointer",
        isSelected
          ? "bg-blue-600 text-blue-300"
          : isHovered
            ? "text-gray-400 border-gray-200 bg-gray-100"
            : "text-gray-400 border-gray-200"
      )}
      title={`${fn.name} (${fn.frameSpans.length} calls)`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={elementRef}
    >
      <div className="pr-1 font-mono text-xs overflow-hidden text-ellipsis whitespace-nowrap">
        <span className={isSelected ? "" : ""}>{prefix}</span>
        <span
          className={clsx(
            "font-medium",
            isSelected ? "text-white" : "text-gray-800"
          )}
        >
          {name}
        </span>
        <span className="pl-0.5">
          (
          {Object.entries(args)
            .map(([name]) => name)
            .join(", ")}
          )
        </span>
      </div>
      <div className="text-xs">{fn.frameSpans.length}</div>
    </div>
  );
};

export const FunctionsPane = ({
  functions,
  selected,
  onSelect,
  hovered,
  onHover,
}: {
  functions: FunctionWithFrameSpans[] | undefined;
  selected: SelectionState;
  onSelect: OnSelectionState;
  hovered: SelectionState;
  onHover: OnSelectionState;
}) => {
  const elementRefs = useRef<Record<string, HTMLDivElement>>({});
  // Clear the refs when functions change
  useEffect(() => {
    elementRefs.current = {};
  }, [functions]);
  useLayoutEffect(() => {
    if (!selected.functionId) return;
    const element = elementRefs.current[selected.functionId];
    if (!element) return;
    element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }, [selected.functionId]);
  const header = (
    <div className="flex items-center">
      <h4 className="flex-1">
        Functions {functions?.length ? `(${functions.length})` : ""}
      </h4>
      <div className="text-xs text-gray-400"># of calls</div>
    </div>
  );
  return (
    <ScrollablePane header={header}>
      {functions?.map((fn) => (
        <FunctionBlock
          key={`${fn.module}:${fn.defLineNumber}`}
          fn={fn}
          onSelect={onSelect}
          isSelected={fn.id === selected.functionId}
          onHover={onHover}
          isHovered={fn.id === hovered.functionId}
          elementRef={(el) => el && (elementRefs.current[fn.id] = el)}
        />
      ))}
    </ScrollablePane>
  );
};
